import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import {
    container as containerClass,
    items as itemsClass,
    filter as filterClass,
    filterItem as filterItemClass,
    filterItemIsActive as filterItemIsActiveClass,
} from "~styles/elements/grid/products-grid.module.scss"
import { getImage, isBrowser, resolveLink, translate } from "~utility/Sink"
import cx from "classnames"

/**
 *
 * @param {products} param0
 * @returns
 */
function getUniqueCategories({ products }) {
    const categories = []

    products
        .map(item => item.node.data.categories)
        .map(items =>
            items.map(
                category =>
                    category.category.document &&
                    categories.push({
                        id: category.category.document.id,
                        slug: category.category.slug,
                        name: category.category.document.data.name.text,
                        sort_order: category.category.document.data.sort_order,
                    })
            )
        )

    return categories
        .sort((a, b) => a.sort_order - b.sort_order)
        .filter(
            (value, index, array) =>
                array.findIndex(el => el.id == value.id) == index
        )
}

const ProductsGridFilter = ({
    language,
    filterValue,
    onChange,
    categories,
}) => {
    return (
        <div className={filterClass}>
            <div
                className={cx(filterItemClass, {
                    [filterItemIsActiveClass]:
                        !filterValue || filterValue === "false",
                })}
                onClick={() => onChange(false)}
            >
                {translate("products_grid.filter_all", language)}
            </div>
            {categories.map(category => (
                <div
                    key={category.id}
                    className={cx(filterItemClass, {
                        [filterItemIsActiveClass]: category.id === filterValue,
                    })}
                    onClick={() => onChange(category.id)}
                >
                    {category.name}
                </div>
            ))}
        </div>
    )
}

export const ProductsGrid = ({
    activeDocMeta,
    products,
    showFilter = true,
}) => {
    const [filter, setFilter] = useState(
        isBrowser()
            ? window.location.hash
                ? window.location.hash.substr(1)
                : false
            : false
    )
    const [items, setItems] = useState(products)
    const categories = getUniqueCategories({ products: products })

    function getItems(category) {
        if (!category || category === "false") {
            return products
        }

        let prop = [...products]

        return prop.filter(
            el =>
                el.node.data.categories &&
                el.node.data.categories.length > 0 &&
                el.node.data.categories.find(
                    item =>
                        item.category.document &&
                        item.category.document.id === category
                )
        )
    }

    useEffect(() => {
        setItems(getItems(filter))
    }, [filter])

    const onChange = value => {
        if (isBrowser()) {
            window.history.pushState(null, null, "#" + value)
        }
        setFilter(value)
    }

    return (
        <div className={containerClass}>
            {showFilter && (
                <ProductsGridFilter
                    language={activeDocMeta.lang}
                    categories={categories}
                    filterValue={filter}
                    onChange={value => onChange(value)}
                />
            )}
            <div className={itemsClass}>
                {items.map(product => (
                    <div key={product.node.uid + product.node.lang}>
                        <Link to={resolveLink(product.node)}>
                            <img
                                src={product.node.data.logo.url}
                                alt={product.node.data.name.text}
                            />
                            <span>
                                <img
                                    alt={product.node.data.name.text}
                                    src={getImage(
                                        product.node.data.main_image.url,
                                        { width: 500, height: "auto" }
                                    )}
                                />
                            </span>
                            <p>{product.node.data.teaser.text}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}
