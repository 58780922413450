import React from "react"

import cx from "classnames"

import { translate } from "~utility/Sink"

import * as styles from "./ConsentBlocker.module.scss"

export const ConsentBlocker = ({
    children,
    lang,
    label,
    isBlocked = true,
    style = {},
}) => {
    return (
        <div className={cx(styles.root)} style={style}>
            {isBlocked ? (
                <div className={styles.BlockedContent}>
                    <div className={styles.BlockedText}>
                        {label}
                        &nbsp;
                        {translate("consent_blocker.blocked", lang)}
                        &nbsp;
                        <a
                            href="#"
                            onClick={event => {
                                event.preventDefault()
                                window.CCM.openWidget()
                            }}
                        >
                            {translate("footer.consent_widget_open", lang)}
                        </a>
                        .
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    )
}
