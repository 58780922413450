import React from "react"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import * as styles from "~styles/elements/list/investor-relations.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { RteText } from "~components/atoms/RteText"

export const StockInfo = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query InvestorRelationsStockQuery {
            settings: allPrismicSettings {
                edges {
                    node {
                        lang
                        data {
                            share_basic_data {
                                html
                            }
                        }
                    }
                }
            }
        }
    `)

    const settings = data.settings.edges.find(
        item => item.node.lang === language
    ).node.data

    return (
        <GridRow width="content">
            <GridColumn s={12} m={7} l={12}>
                <div className={styles.stock}>
                    <div>
                        <RteText content={settings.share_basic_data.html} />
                    </div>
                </div>
            </GridColumn>
        </GridRow>
    )
}
