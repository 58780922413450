import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { localizeRoute, translate } from "~utility/Sink"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/financial-calendar-list.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Box } from "~components/atoms/Box"

import cx from "classnames"
import { trackFileDownload } from "../../utility/tracking"

export const FinancialCalendarList = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang
    const [filter, setFilter] = useState(new Date().getFullYear())

    const data = useStaticQuery(graphql`
        query {
            events: allPrismicFinancialCalendarEvent(
                sort: { fields: data___date_begin, order: ASC }
            ) {
                edges {
                    node {
                        lang
                        data {
                            text {
                                html
                            }
                            name {
                                text
                            }
                            download {
                                url
                                document {
                                    ... on PrismicDownload {
                                        id
                                        data {
                                            file {
                                                url
                                                size
                                            }
                                        }
                                    }
                                }
                            }
                            date_end
                            date_begin
                            date_as_text {
                                text
                            }
                            annual_general_meeting {
                                document {
                                    ... on PrismicGeneralMeeting {
                                        id
                                        data {
                                            name {
                                                text
                                            }
                                        }
                                        uid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const items = data.events.edges.filter(item => item.node.lang === language)

    let years = items
        .map(item => new Date(item.node.data.date_begin).getFullYear())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => b - a)

    return (
        <div className={styles.list}>
            <GridRow width="content">
                <div className={styles.filter}>
                    {years.map(year => (
                        <div
                            key={year}
                            onClick={() => setFilter(year)}
                            className={cx(styles.filterItem, {
                                [styles.filterItemIsActive]: year === filter,
                            })}
                        >
                            {year}
                        </div>
                    ))}
                </div>
            </GridRow>
            <GridRow width="content">
                {items
                    .filter(
                        item =>
                            new Date(
                                item.node.data.date_begin
                            ).getFullYear() === filter
                    )
                    .map(item => {
                        const isDocumentAttached =
                            item.node.data.download.document?.data?.file?.url
                        const isMeetingLinked =
                            item.node.data.annual_general_meeting.document

                        const link = isDocumentAttached
                            ? item.node.data.download.document?.data?.file?.url
                            : isMeetingLinked
                            ? `${localizeRoute(
                                  "investor_relations.annual_general_meeting",
                                  language
                              )}/${
                                  item.node.data.annual_general_meeting.document
                                      .uid
                              }`
                            : false

                        const buttonLabel = isDocumentAttached
                            ? translate("download", language)
                            : isMeetingLinked
                            ? translate("read_more", language)
                            : false

                        const clickHandler = isDocumentAttached
                            ? () => {
                                  trackFileDownload(
                                      item.node.data.file.url,
                                      item.node.data.file.size
                                  )
                              }
                            : undefined

                        return (
                            <GridColumn
                                key={item.node.id}
                                gutterBottom
                                s={12}
                                m={12}
                                l={12}
                            >
                                <Box
                                    padding
                                    className={styles.item}
                                    externalLink={isDocumentAttached}
                                    to={link}
                                    buttonLabel={buttonLabel}
                                    onClick={clickHandler}
                                >
                                    <div>
                                        <time>
                                            {item.node.data.date_as_text.text &&
                                                item.node.data.date_as_text
                                                    .text}
                                            {!item.node.data.date_as_text
                                                .text &&
                                                item.node.data.date_begin ===
                                                    item.node.data.date_end && (
                                                    <LocalizedDate
                                                        date={
                                                            new Date(
                                                                item.node.data.date_begin
                                                            )
                                                        }
                                                    />
                                                )}
                                            {!item.node.data.date_as_text
                                                .text &&
                                                item.node.data.date_begin !==
                                                    item.node.data.date_end && (
                                                    <>
                                                        <LocalizedDate
                                                            date={
                                                                new Date(
                                                                    item.node.data.date_begin
                                                                )
                                                            }
                                                        />{" "}
                                                        –{" "}
                                                        <LocalizedDate
                                                            date={
                                                                new Date(
                                                                    item.node.data.date_end
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </time>
                                        <Headline variant="h4">
                                            {item.node.data.name.text}
                                        </Headline>
                                    </div>
                                </Box>
                            </GridColumn>
                        )
                    })}
            </GridRow>
        </div>
    )
}
