import React, { useState } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { localizeRoute, resolveLink, translate } from "~utility/Sink"
import { Text } from "~components/atoms/Text"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/newsroom-grid.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Box } from "~components/atoms/Box"

import cx from "classnames"
import { ContentSection } from "~components/atoms/ContentSection"

export const NewsroomGrid = ({ headline, activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query {
            news: allPrismicNews(sort: { fields: data___date, order: DESC }) {
                edges {
                    node {
                        id
                        data {
                            type
                            name {
                                text
                            }
                            description {
                                text
                            }
                            date
                            image {
                                url
                            }
                        }
                        type
                        uid
                        lang
                    }
                }
            }
            exhibitions: allPrismicExhibition(
                sort: { fields: data___date_begin, order: ASC }
            ) {
                edges {
                    node {
                        id
                        lang
                        type
                        isFuture
                        data {
                            address {
                                text
                            }
                            booth_number
                            date_begin
                            date_end
                            link {
                                url
                            }
                            logo {
                                url
                            }
                            teaser_image {
                                url
                            }
                            name {
                                text
                            }
                            text {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    const news = data.news.edges.filter(item => item.node.lang === language)

    const exhibitions = data.exhibitions.edges
        .filter(item => item.node.lang === language)
        .filter(
            item =>
                new Date(item.node.data.date_begin) >= new Date() ||
                (new Date(item.node.data.date_begin) <= new Date() &&
                    new Date(item.node.data.date_end) >= new Date())
        )
        .slice(0, 1)

    return (
        <div className={styles.root}>
            <GridRow width="content">
                <GridColumn s={12} m={12} l={12}>
                    <ContentSection>
                        <Headline variant="h2">{headline}</Headline>
                        <div className={styles.list}>
                            <div className={styles.listBigTeaser}>
                                {news.slice(0, 1).map((item, index) => (
                                    <div
                                        key={`cpnews-${index}`}
                                        className={styles.item}
                                        onClick={() =>
                                            navigate(resolveLink(item.node))
                                        }
                                    >
                                        {item.node.data.type ===
                                            "Corporate News" && (
                                            <span>
                                                {translate(
                                                    "newsroom.headline.corporate_news",
                                                    language
                                                )}
                                            </span>
                                        )}
                                        {item.node.data.type ===
                                            "Ad-hoc Meldungen" && (
                                            <span>
                                                {translate(
                                                    "newsroom.headline.ad_hoc_release",
                                                    language
                                                )}
                                            </span>
                                        )}
                                        <img src={item.node.data.image.url} />
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.listSmallTeasers}>
                                {exhibitions.map((item, index) => (
                                    <div
                                        key={`exhibition-${index}`}
                                        className={styles.item}
                                        onClick={() =>
                                            navigate(
                                                localizeRoute(
                                                    "newsroom.exhibitions",
                                                    language
                                                )
                                            )
                                        }
                                    >
                                        <span>
                                            {translate(
                                                "newsroom.headline.exhibition",
                                                language
                                            )}
                                        </span>
                                        <img
                                            src={
                                                item.node.data.teaser_image.url
                                            }
                                        />
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_begin
                                                    )
                                                }
                                            />{" "}
                                            –{" "}
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_end
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                ))}
                                {news.slice(1, 4).map((item, index) => (
                                    <div
                                        key={`news-${index}`}
                                        className={styles.item}
                                        onClick={() =>
                                            navigate(resolveLink(item.node))
                                        }
                                    >
                                        {item.node.data.type ===
                                            "Corporate News" && (
                                            <span>
                                                {translate(
                                                    "newsroom.headline.corporate_news",
                                                    language
                                                )}
                                            </span>
                                        )}
                                        {item.node.data.type ===
                                            "Ad-hoc Meldungen" && (
                                            <span>
                                                {translate(
                                                    "newsroom.headline.ad_hoc_release",
                                                    language
                                                )}
                                            </span>
                                        )}
                                        <img src={item.node.data.image.url} />
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ContentSection>
                </GridColumn>
            </GridRow>
        </div>
    )
}
