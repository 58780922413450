import React from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { resolveLink } from "~utility/Sink"
import * as styles from "~styles/elements/list/solution-list.module.scss"
import { Box } from "~components/atoms/Box"
import { RteText } from "~components/atoms/RteText"

const SolutionItem = ({ item }) => {
    return (
        <Box
            className={styles.item}
            onClick={() => navigate(resolveLink(item))}
        >
            <img src={item.data.main_image.url} />
            <div>
                <div>
                    <h2>{item.data.name.text}</h2>
                    <RteText content={item.data.abstract.html} />
                </div>
            </div>
        </Box>
    )
}

export const SolutionList = ({ activeDocMeta }) => {
    const data = useStaticQuery(graphql`
        query SolutionsListQuery {
            solutions: allPrismicSolution(
                sort: { fields: data___sort_order, order: ASC }
            ) {
                edges {
                    node {
                        uid
                        type
                        lang
                        data {
                            main_image {
                                url(imgixParams: { w: 2000 })
                                tabletImage: url(imgixParams: { w: 1300 })
                                mobileImage: url(imgixParams: { w: 800 })
                            }
                            name {
                                text
                            }
                            abstract {
                                html
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.list}>
            {data.solutions.edges
                .filter(item => item.node.lang === activeDocMeta.lang)
                .map(el => el.node)
                .map(item => (
                    <SolutionItem key={item.uid} item={item} />
                ))}
        </div>
    )
}
