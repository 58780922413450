import React, { useEffect, useState, useRef } from "react"
import { Headline } from "~components/atoms/Headline"
import * as styles from "~styles/elements/list/location-list.module.scss"

import * as mapboxgl from "!mapbox-gl"
import { graphql, useStaticQuery } from "gatsby"
import { RteText } from "~components/atoms/RteText"
import ArrowRedIcon from "~images/icon/icon-arrow-red.svg"
import cx from "classnames"
import { ContentSection } from "~components/atoms/ContentSection"
import { translate } from "~utility/Sink"
import useConsent, { EMBEDDINGS } from "../../utility/hooks/UseConsent"
import { ConsentBlocker } from "./ConsentBlocker/ConsentBlocker"

mapboxgl.accessToken =
    "pk.eyJ1IjoiY2VudHJvdGhlcm0iLCJhIjoiY2tvYmFlb2F5MDYzNDJubjFzN29laG5hZSJ9.4nldmNuGeRof1Y3t8FBONg"

export function LocationMap({ activeDocMeta, headline, subline, text }) {
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [lng, setLng] = useState(9.769928)
    const [lat, setLat] = useState(47.6194477)
    const [zoom, setZoom] = useState(1.5)

    const [detailId, setDetailId] = useState(false)

    const { isEmbeddingAccepted } = useConsent()

    const mapboxConsentGiven = isEmbeddingAccepted(EMBEDDINGS.MAPBOX)

    const data = useStaticQuery(graphql`
        query LocationMapQuery {
            locations: allPrismicLocation(
                sort: { order: ASC, fields: data___sort_order }
            ) {
                edges {
                    node {
                        id
                        lang
                        data {
                            telephone {
                                text
                            }
                            roles {
                                role
                            }
                            name {
                                text
                            }
                            image {
                                url
                            }
                            fax {
                                text
                            }
                            website {
                                text
                            }
                            email {
                                text
                            }
                            description {
                                text
                            }
                            coordinates {
                                longitude
                                latitude
                            }
                            address {
                                html
                            }
                        }
                    }
                }
            }
        }
    `)

    useEffect(() => {
        if (map.current || !mapboxConsentGiven) return // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            center: [lng, lat],
            zoom: zoom,
            style: "mapbox://styles/centrotherm/ckoyem3x92gb318k8mcgygfaj",
        })

        let features = []

        data.locations.edges
            .filter(item => item.node.lang === activeDocMeta.lang)
            .map(item => item.node.data)
            .map(location => {
                features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [
                            location.coordinates.longitude,
                            location.coordinates.latitude,
                        ],
                    },
                    properties: location,
                })
            })

        map.current.on("load", () => {
            features.forEach(function (marker) {
                // Create a DOM element for each marker.
                var el = document.createElement("div")
                el.className = cx(styles.marker, {
                    [styles.markerIsDark]: marker.properties.roles.find(
                        el => el.role === "Sales Representative"
                    ),
                })

                el.addEventListener("click", function () {
                    setDetailId(marker.properties.name.text)
                    map.current.flyTo({
                        center: [
                            marker.properties.coordinates.longitude,
                            marker.properties.coordinates.latitude,
                        ],
                        zoom: 8,
                    })
                })

                // Add markers to the map.
                new mapboxgl.Marker(el)
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current)
            })
        })
    }, [mapboxConsentGiven])

    const selectLocation = (lng, lat, id) => {
        setDetailId(id)
        setLat(lat)
        setLng(lng)
        setZoom(8)

        map.current.flyTo({ center: [lng, lat], zoom: 8 })
    }

    const resetMap = () => {
        map.current.flyTo({ center: [9.769928, 47.6194477], zoom: 1.5 })
        setDetailId(false)
    }

    return (
        <div className={styles.root}>
            {mapboxConsentGiven && (
                <link
                    href="https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css"
                    rel="stylesheet"
                />
            )}
            <header className={styles.header}>
                <ContentSection>
                    {subline && <Headline variant="h5">{subline}</Headline>}
                    {headline && <Headline variant="h2">{headline}</Headline>}
                    {text && <RteText content={text} />}
                </ContentSection>
            </header>

            <div className={styles.map}>
                <div ref={mapContainer} className={styles.mapbox}>
                    {!mapboxConsentGiven ? (
                        <ConsentBlocker
                            style={{ height: "100%", width: "100%" }}
                            label="MapBox"
                            lang={activeDocMeta.lang}
                        />
                    ) : undefined}
                </div>
                <div className={styles.list}>
                    <header
                        onClick={() => resetMap()}
                        className={cx(styles.listHeader, {
                            [styles.listHeaderIsActive]: detailId,
                        })}
                    >
                        <img src={ArrowRedIcon} />{" "}
                        {translate(
                            "location_map.back_to_list",
                            activeDocMeta.lang
                        )}
                    </header>
                    {data.locations.edges
                        .filter(item => item.node.lang === activeDocMeta.lang)
                        .map(item => item.node.data)
                        .filter(item =>
                            detailId ? item.name.text === detailId : true
                        )
                        .map((location, i) => (
                            <div
                                key={i}
                                onClick={() =>
                                    selectLocation(
                                        location.coordinates.longitude,
                                        location.coordinates.latitude,
                                        location.name.text
                                    )
                                }
                                className={cx(styles.listItem, {
                                    [styles.listItemIsActive]:
                                        detailId === location.name.text,
                                })}
                            >
                                {location.image.url && (
                                    <img src={location.image.url} alt="" />
                                )}
                                <Headline gutterBottom={false} variant="h4">
                                    {location.name.text}
                                </Headline>
                                <div>
                                    <RteText content={location.address.html} />

                                    {location.telephone.text}
                                    <br />
                                    {location.email.text && (
                                        <a
                                            className={styles.listItemLink}
                                            href={`mailto:${location.email.text}`}
                                        >
                                            {location.email.text}
                                        </a>
                                    )}
                                    <br />
                                    {location.website.text && (
                                        <a
                                            target="_blank"
                                            className={styles.listItemLink}
                                            href={`https://${location.website.text}`}
                                        >
                                            {location.website.text}
                                        </a>
                                    )}
                                </div>
                                <div className={styles.listItemRoles}>
                                    {location.roles.map(item => (
                                        <span key={item.role}>{item.role}</span>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
