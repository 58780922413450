import React from "react"
import { ContentSection } from "~components/atoms/ContentSection"
import { Headline } from "~components/atoms/Headline"
import cx from "classnames"

import * as styles from "~styles/elements/slices/text-banner.module.scss"
import { RteText } from "~components/atoms/RteText"

export function TextBannerSlice({ data }) {
    const primary = data.primary
    const items = data.items

    return (
        <div className={cx(styles.root, styles[`theme${primary.layout}`])}>
            <ContentSection>
                <div className={styles.header}>
                    {primary.subline.text && (
                        <Headline gutterBottom={false} variant="h5">
                            {primary.subline.text}
                        </Headline>
                    )}
                    {primary.headline.text && (
                        <Headline variant="h2" className={styles.headline}>
                            {primary.headline.text}
                        </Headline>
                    )}
                </div>
                <div className={styles.items}>
                    {items.map((item, i) => (
                        <div key={i} className={styles.item}>
                            {item.icon.url && (
                                <div className={styles.icon}>
                                    <img src={item.icon.url} />
                                </div>
                            )}
                            {(primary.layout === "3" ||
                                primary.layout === "5") && (
                                <div className={styles.number}>{i + 1}</div>
                            )}
                            <Headline gutterBottom={false} variant="h5">
                                {item.headline.text}
                            </Headline>
                            <RteText content={item.text.html} />
                        </div>
                    ))}
                </div>
            </ContentSection>
        </div>
    )
}
