import React from "react"
import { Button } from "~components/atoms/Button"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import cx from "classnames"
import * as styles from "~styles/elements/slices/large-image-teaser.module.scss"
import * as layoutStyles from "~styles/elements/layouts/default-layout.module.scss"
import { RteText } from "~components/atoms/RteText"

export function LargeImageTeaserSlice({ data }) {
    const item = data.primary

    return (
        <ContentSection
            className={cx({
                [layoutStyles.isDark]:
                    item.layout === "3" || item.layout === "4",
            })}
        >
            <GridRow width="content">
                <GridColumn s={12} m={12} l={12}>
                    <div
                        className={cx(
                            styles.root,
                            styles[`theme${item.layout}`]
                        )}
                    >
                        <div className={styles.text}>
                            {item.icon.url && (
                                <img src={item.icon.url} alt="" />
                            )}
                            {item.headline.text && (
                                <Headline gutterBottom={false} variant="h3">
                                    {item.headline.text}
                                </Headline>
                            )}
                            <RteText content={item.text.html} />
                            {item.link && item.link.uid && (
                                <Button withArrow link={item.link}>
                                    {item.button_label.text}
                                </Button>
                            )}
                        </div>
                        <div className={styles.image}>
                            <img
                                src={item.image.url}
                                alt={item.headline.text}
                            />
                        </div>
                    </div>
                </GridColumn>
            </GridRow>
        </ContentSection>
    )
}
