import React from "react"
import * as styles from "~styles/elements/list/job-list.module.scss"
import * as buttonStyles from "~styles/atoms/button.module.scss"

import * as jobs from "../../data/jobs.json"
import { Headline } from "~components/atoms/Headline"
import { ContentSection } from "~components/atoms/ContentSection"
import { RteText } from "~components/atoms/RteText"
import { Link, navigate } from "gatsby"
import { localizeRoute, translate } from "~utility/Sink"

import cx from "classnames"
import { useSettings } from "~utility/hooks/UseSettings"

const JobItem = ({ item, lang }) => {
    return (
        <div
            className={styles.item}
            onClick={() =>
                navigate(
                    `${localizeRoute("career.job_detail", "de-de")}/${
                        item.jobPostingId
                    }`
                )
            }
        >
            <div>
                <Headline>
                    <Link
                        to={`${localizeRoute("career.job_detail", "de-de")}/${
                            item.jobPostingId
                        }`}
                    >
                        {item.title}
                    </Link>
                </Headline>
                {item.config.ProjectGeoLocationCity && (
                    <span>{item.config.ProjectGeoLocationCity[0]}</span>
                )}
            </div>
        </div>
    )
}

export const JobList = ({ headline, subline, text, activeDocMeta }) => {
    const settings = useSettings({ language: activeDocMeta.lang })

    return (
        <div className={styles.root}>
            <ContentSection>
                {headline && (
                    <header className={styles.header}>
                        {subline && (
                            <Headline variant="h5" gutterBottom={false}>
                                {subline}
                            </Headline>
                        )}
                        {headline && (
                            <Headline variant="h2">{headline}</Headline>
                        )}
                        {text && <RteText content={text} />}
                    </header>
                )}
                {jobs.results
                    .filter(
                        item =>
                            item.config.softgarden_type[0] ===
                            "STEPSTONE_LIQUID_DESIGN"
                    )
                    .filter(
                        item =>
                            item.config.softgarden_keywords &&
                            item.config.softgarden_keywords[0] &&
                            item.config.softgarden_keywords[0].includes(
                                "Top Stelle"
                            )
                    )
                    .sort((a, b) => a.jobStartDate - b.jobStartDate)
                    .map((item, index) => (
                        <JobItem
                            key={index}
                            lang={activeDocMeta.lang}
                            item={item}
                        />
                    ))}
                <div className={styles.buttons}>
                    <Link
                        className={cx(
                            buttonStyles.root,
                            buttonStyles.withArrow,
                            styles.button
                        )}
                        to={localizeRoute(
                            "career.all_jobs",
                            activeDocMeta.lang
                        )}
                    >
                        {translate("career.all_vacancies", activeDocMeta.lang)}
                    </Link>
                    <a
                        target="_blank"
                        href={settings.link_speculative_application.url}
                        className={cx(buttonStyles.root, buttonStyles.outlined)}
                    >
                        {translate(
                            "career.speculative_application",
                            activeDocMeta.lang
                        )}
                    </a>
                </div>
            </ContentSection>
        </div>
    )
}
