import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { DownloadsList } from "~components/elements/DownloadsList"

export const NewsroomDownloadsList = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query {
            downloads: allPrismicDownload(
                filter: { tags: { in: "PR" } }
                sort: { fields: data___publication_date, order: DESC }
            ) {
                edges {
                    node {
                        id
                        lang
                        data {
                            file {
                                url
                                size
                            }
                            image {
                                fixed(height: 350, width: 200) {
                                    src
                                }
                            }
                            name {
                                text
                            }
                            publication_date
                            teaser {
                                text
                            }
                            categories {
                                category {
                                    document {
                                        ... on PrismicCategory {
                                            id
                                            data {
                                                name {
                                                    text
                                                }
                                                sort_order
                                                type
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const items = data.downloads.edges.filter(
        item => item.node.lang === language
    )

    return <DownloadsList items={items} language={language} />
}
