import React, { useState } from "react"
import * as styles from "~styles/elements/list/job-list.module.scss"
import * as buttonStyles from "~styles/atoms/button.module.scss"

import * as jobs from "../../data/jobs.json"
import { Headline } from "~components/atoms/Headline"
import { ContentSection } from "~components/atoms/ContentSection"
import { RteText } from "~components/atoms/RteText"
import { Link, navigate } from "gatsby"
import { localizeRoute, translate } from "~utility/Sink"

import cx from "classnames"
import { useSettings } from "~utility/hooks/UseSettings"
import FilterItem from "../../components/FilterItem/FilterItem"
import FilterLayout from "../../components/FilterLayout/FilterLayout"
import FilterLayoutGroup from "../../components/FilterLayout/FilterLayoutGroup"

const jobCategoryMapping = {
    engineering: ["sam42_engineering", "sam42_design", "sam42_science"],
    manual: ["sam42_manualwork", "sam42_purchase"],
    commercial: [
        "sam42_administration",
        "sam42_finance",
        "sam42_sales",
        "sam42_law",
        "sam42_hr",
        "sam42_marketing",
        "515a2f5f-ba60-4e90-99cf-b0cf7deea1ab", // purchasing
    ],
    it: ["sam42_it"],
}

const careerLevelMapping = {
    experiencedProfessional: ["Experienced Professional::Berufserfahrene"],
    studentApprentice: ["Student::Student/in", "Apprentice::Auszubildende"],
    youngPrefessional: ["Young Professional::Berufseinsteiger"],
}

const JobItem = ({ item, lang }) => {
    // Always link to the german version of the job detail page
    return (
        <div
            className={styles.item}
            onClick={() =>
                navigate(
                    `${localizeRoute("career.job_detail", "de-de")}/${
                        item.jobPostingId
                    }`
                )
            }
        >
            <div>
                <Headline>
                    <Link
                        to={`${localizeRoute("career.job_detail", "de-de")}/${
                            item.jobPostingId
                        }`}
                    >
                        {item.title}
                    </Link>
                </Headline>
                {item.config.ProjectGeoLocationCity && (
                    <span>{item.config.ProjectGeoLocationCity[0]}</span>
                )}
            </div>
        </div>
    )
}

export const FullJobList = ({ headline, subline, text, activeDocMeta }) => {
    const settings = useSettings({ language: activeDocMeta.lang })

    const [activeJobCategoriesFilter, setActiveJobCategoriesFilter] =
        useState(undefined)
    const [activeCareerLevelFilter, setActiveCareerLevelFilter] =
        useState(undefined)

    // Map
    const extendedJobs = jobs.results.map(job => ({
        ...job,
        internalCategory: Object.keys(jobCategoryMapping).find(jobCategory =>
            jobCategoryMapping[jobCategory].includes(job.config.jobcategory[0])
        ),
        internalCareerLevel:
            Object.keys(careerLevelMapping).find(careerLevel =>
                careerLevelMapping[careerLevel].includes(
                    job.config.softgarden_careerLevel_values[0]
                )
            ) || job.config.softgarden_careerLevel_values[0],
    }))

    const filteredJobs = React.useMemo(
        () =>
            extendedJobs
                .filter(
                    item =>
                        item.config.softgarden_type[0] ===
                        "STEPSTONE_LIQUID_DESIGN"
                )
                .filter(item => {
                    if (activeJobCategoriesFilter === undefined) {
                        return true
                    }
                    return item.internalCategory === activeJobCategoriesFilter
                })
                .filter(item => {
                    if (activeCareerLevelFilter === undefined) {
                        return true
                    }
                    return item.internalCareerLevel === activeCareerLevelFilter
                })
                .sort((a, b) => a.jobStartDate - b.jobStartDate),
        [activeJobCategoriesFilter, activeCareerLevelFilter, extendedJobs]
    )

    const possibleJobCategories = extendedJobs.reduce((acc, job) => {
        return {
            ...acc,
            [job.internalCategory]: {
                label: translate(
                    `career.job_category_${job.internalCategory}`,
                    activeDocMeta.lang
                ),
                independentCount: extendedJobs.filter(
                    countedJop =>
                        countedJop.internalCategory === job.internalCategory
                ).length,
                count: filteredJobs.filter(
                    countedJop =>
                        countedJop.internalCategory === job.internalCategory
                ).length,
            },
        }
    }, {})

    const possibleCareerLevels = extendedJobs.reduce((acc, job) => {
        return {
            ...acc,
            [job.internalCareerLevel]: {
                label: translate(
                    `career.career_level_${job.internalCareerLevel}`,
                    activeDocMeta.lang
                ),
                independentCount: extendedJobs.filter(
                    countedJop =>
                        countedJop.internalCareerLevel ===
                        job.internalCareerLevel
                ).length,
                count: filteredJobs.filter(
                    countedJop =>
                        countedJop.internalCareerLevel ===
                        job.internalCareerLevel
                ).length,
            },
        }
    }, {})

    return (
        <div className={styles.root}>
            <ContentSection>
                {headline && (
                    <header className={styles.header}>
                        {subline && (
                            <Headline variant="h5" gutterBottom={false}>
                                {subline}
                            </Headline>
                        )}
                        {headline && (
                            <Headline variant="h2">{headline}</Headline>
                        )}
                        {text && <RteText content={text} />}
                    </header>
                )}
                <FilterLayout>
                    <FilterLayoutGroup
                        label={translate(
                            "career.filter_job_category_label",
                            activeDocMeta.lang
                        )}
                    >
                        <FilterItem
                            active={activeJobCategoriesFilter === undefined}
                            value={undefined}
                            label={translate(
                                "career.filter_all",
                                activeDocMeta.lang
                            )}
                            onClick={setActiveJobCategoriesFilter}
                        />
                        {Object.keys(possibleJobCategories).map(
                            (jobCategory, index) => (
                                <FilterItem
                                    active={
                                        jobCategory ===
                                        activeJobCategoriesFilter
                                    }
                                    onClick={value =>
                                        setActiveJobCategoriesFilter(
                                            activeJobCategoriesFilter === value
                                                ? undefined
                                                : value
                                        )
                                    }
                                    key={index}
                                    value={jobCategory}
                                    label={
                                        possibleJobCategories[jobCategory].label
                                    }
                                />
                            )
                        )}
                    </FilterLayoutGroup>
                    <FilterLayoutGroup
                        label={translate(
                            "career.filter_career_level_label",
                            activeDocMeta.lang
                        )}
                    >
                        <FilterItem
                            active={activeCareerLevelFilter === undefined}
                            value={undefined}
                            label={translate(
                                "career.filter_all",
                                activeDocMeta.lang
                            )}
                            onClick={setActiveCareerLevelFilter}
                        />
                        {Object.keys(possibleCareerLevels).map(
                            (careerLevel, index) => (
                                <FilterItem
                                    active={
                                        careerLevel === activeCareerLevelFilter
                                    }
                                    onClick={value =>
                                        setActiveCareerLevelFilter(
                                            activeCareerLevelFilter === value
                                                ? undefined
                                                : value
                                        )
                                    }
                                    key={index}
                                    value={careerLevel}
                                    label={
                                        possibleCareerLevels[careerLevel].label
                                    }
                                />
                            )
                        )}
                    </FilterLayoutGroup>
                </FilterLayout>
                {filteredJobs.map((item, index) => (
                    <JobItem
                        key={index}
                        lang={activeDocMeta.lang}
                        item={item}
                    />
                ))}
                {!filteredJobs.length && (
                    <div className={styles.empty}>
                        {translate("career.list_empty", activeDocMeta.lang)}
                    </div>
                )}
                <div className={styles.buttons}>
                    <a
                        target="_blank"
                        href={settings.link_speculative_application.url}
                        className={cx(buttonStyles.root, buttonStyles.outlined)}
                    >
                        {translate(
                            "career.speculative_application",
                            activeDocMeta.lang
                        )}
                    </a>
                </div>
            </ContentSection>
        </div>
    )
}
