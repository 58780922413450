import { navigate } from "gatsby-link"
import React from "react"
import cx from "classnames"
import { Button } from "~components/atoms/Button"
import { Headline } from "~components/atoms/Headline"

import * as styles from "~styles/elements/slices/text-teaser.module.scss"
import { resolveLink } from "~utility/Sink"
import { ContentSection } from "~components/atoms/ContentSection"
import { RteText } from "~components/atoms/RteText"

export function TextTeaserSlice({ data }) {
    const item = data.primary

    return (
        <div className={cx(styles.root, styles[`theme${item.layout}`])}>
            <ContentSection>
                <div className={styles.inner}>
                    <div className={styles.text}>
                        <Headline gutterBottom={false} variant="h3">
                            {item.headline.text}
                        </Headline>
                        <RteText content={item.text.html} />
                    </div>
                    <div className={styles.button}>
                        <Button withArrow link={item.link}>
                            {item.button_label.text}
                        </Button>
                    </div>
                </div>
            </ContentSection>
        </div>
    )
}
