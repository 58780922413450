// extracted by mini-css-extract-plugin
export var header = "V_B";
export var item = "V_cg";
export var itemImage = "V_cz";
export var itemIsExternal = "V_cN";
export var itemIsInView = "V_cL";
export var itemIsLinked = "V_cM";
export var itemText = "V_cB";
export var itemTime = "V_cP";
export var itemTimeIsBig = "V_cQ";
export var logo = "V_bH";
export var root = "V_s";