import React from "react"
import { InvestorRelationsBoard } from "~components/elements/InvestorRelationsBoard"
import { JobList } from "~components/elements/JobList"
import { LocationMap } from "~components/elements/LocationMap"
import { CorporateNewsList } from "~components/elements/CorporateNewsList"
import { NewsroomBoard } from "~components/elements/NewsroomBoard"
import { SolutionList } from "~components/elements/SolutionList"
import { AdHocReleasesList } from "~components/elements/AdHocReleasesList"
import { ExhibitionsList } from "~components/elements/ExhibitionsList"
import { StockInfo } from "~components/elements/StockInfo"
import { InvestorRelationsDownloadsList } from "~components/elements/InvestorRelationsDownloadsList"
import { FullJobList } from "~components/elements/FullJobList"
import { NewsroomDownloadsList } from "~components/elements/NewsroomDownloadsList"
import { FinancialCalendarList } from "~components/elements/FinancialCalendarList"
import { AnnualGeneralMeetingList } from "~components/elements/AnnualGeneralMeetingList"
import { NewsroomGrid } from "~components/elements/NewsroomGrid"

export function DataPluginSlice({ activeDocMeta, data }) {
    const primary = data.primary

    const components = {
        map: LocationMap,
        "solution-list": SolutionList,
        "job-list": JobList,
        "newsroom-grid": NewsroomGrid,
        "full-job-list": FullJobList,
        "corporate-news-list": CorporateNewsList,
        "ir-download-list": InvestorRelationsDownloadsList,
        "download-list": NewsroomDownloadsList,
        "ad-hoc-release-list": AdHocReleasesList,
        "stock-info": StockInfo,
        "financial-calendar": FinancialCalendarList,
        "annual-general-meeting-list": AnnualGeneralMeetingList,
        "exhibition-list": ExhibitionsList,
        "investor-relations": InvestorRelationsBoard,
        newsroom: NewsroomBoard,
    }

    const componentId = data.primary.plugin_id.text
    const ElementTag = components[componentId]

    return (
        <ElementTag
            key={data.id}
            activeDocMeta={activeDocMeta}
            headline={primary.headline.text}
            subline={primary.subline.text}
            text={primary.text.html}
        />
    )
}
