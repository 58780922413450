import React from "react"
import { CountingNumbersSlice } from "~components/slices/CountingNumbersSlice"
import { ImageTeaserGroupSlice } from "~components/slices/ImageTeaserGroupSlice"
import { InteractiveVideoSlice } from "~components/slices/InteractiveVideoSlice"
import { TextAndMenuSlice } from "~components/slices/TextAndMenuSlice"
import { QuoteSlice } from "~components/slices/QuoteSlice"
import { TextBlockSlice } from "~components/slices/TextBlockSlice"
import { HeavyTypoHeaderSlice } from "~components/slices/HeavyTypoHeaderSlice"
import { TextTeaserSlice } from "~components/slices/TextTeaserSlice"
import { TextBannerSlice } from "~components/slices/TextBannerSlice"
import { TeaserGridSlice } from "~components/slices/TeaserGridSlice"
import { LargeImageTeaserSlice } from "~components/slices/LargeImageTeaserSlice"
import { HistoryEventsSlice } from "~components/slices/HistoryEventsSlice"
import { ImageTeaserSliderSlice } from "~components/slices/ImageTeaserSliderSlice"
import { QuoteSliderSlice } from "~components/slices/QuoteSliderSlice"
import { ProductsGridSlice } from "~components/slices/ProductsGridSlice"
import { DataPluginSlice } from "~components/slices/DataPluginSlice"
import { TilesSlice } from "~components/slices/TilesSlice"
import { AccordionSlice } from "~components/slices/AccordionSlice"
import { VideoSlice } from "~components/slices/VideoSlice"

export function SliceZoneRenderer({ activeDocMeta, slices }) {
    const components = {
        heavy_typo_header: HeavyTypoHeaderSlice,
        image_teaser_group: ImageTeaserGroupSlice,
        quote: QuoteSlice,
        counting_numbers: CountingNumbersSlice,
        interactive_video: InteractiveVideoSlice,
        text_and_menu: TextAndMenuSlice,
        text_block: TextBlockSlice,
        text_teaser: TextTeaserSlice,
        teaser_grid: TeaserGridSlice,
        large_image_teaser: LargeImageTeaserSlice,
        text_banner: TextBannerSlice,
        history_events: HistoryEventsSlice,
        "quote-slider": QuoteSliderSlice,
        image_teaser_slider: ImageTeaserSliderSlice,
        products_grid: ProductsGridSlice,
        tiles: TilesSlice,
        data_plugin: DataPluginSlice,
        accordion: AccordionSlice,
        video: VideoSlice,
    }

    return (
        <div>
            {slices.map(slice => {
                const ElementTag = components[slice.slice_type]

                if (slice)
                    return (
                        <ElementTag
                            activeDocMeta={activeDocMeta}
                            key={slice.id}
                            data={slice}
                        />
                    )
            })}
        </div>
    )
}
