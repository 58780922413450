import React from "react"
import noop from "lodash/noop"
import cx from "classnames"

import * as styles from "./FilterItem.module.scss"

const FilterItem = ({
    label,
    value,
    count,
    active = false,
    disabled = false,
    onClick = noop,
}) => {
    return (
        <button
            className={cx(styles.FilterItem, {
                [styles.active]: active,
            })}
            onClick={() => onClick(value)}
            disabled={disabled}
        >
            {label}{" "}
            {count !== undefined && (
                <span className={styles.Count}>({count})</span>
            )}
        </button>
    )
}

export default FilterItem
