import React from "react"
import noop from "lodash/noop"
import cx from "classnames"

import * as styles from "./FilterLayoutGroup.module.scss"

const FilterLayoutGroup = ({ children, label }) => {
    return (
        <div className={cx(styles.FilterLayoutGroup, {})}>
            <div className={styles.Label}>{label}</div>
            <div className={styles.Filters}>{children}</div>
        </div>
    )
}

export default FilterLayoutGroup
