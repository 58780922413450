import React from "react"
import { HeavyTypoHeader } from "~components/elements/HeavyTypoHeader"

export function HeavyTypoHeaderSlice({ data }) {
    const item = data.primary

    return (
        <HeavyTypoHeader
            subline={item.subline.text}
            headline={item.headline.text}
            text={item.text.html}
            image={item.image.url}
            gutterBottom={item.layout !== "3"}
            showLogo={item.layout !== "2"}
        />
    )
}
