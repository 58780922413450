import React, { useState } from "react"
import { Text } from "~components/atoms/Text"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import * as styles from "~styles/elements/list/downloads-list.module.scss"
import { Box } from "~components/atoms/Box"
import cx from "classnames"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { translate } from "~utility/Sink"
import { trackFileDownload } from "../../utility/tracking"

export const DownloadsList = ({ items, language }) => {
    const [filter, setFilter] = useState("all")

    let filterItems = []

    items
        .map(item =>
            item.node.data.categories
                .filter(item => item.category.document)
                .map(item => item.category.document)
                .map(item => ({ id: item.id, name: item.data.name.text }))
        )
        .map(items => items.map(item => filterItems.push(item)))

    filterItems = filterItems.filter(
        (value, index, self) =>
            self.findIndex(item => item.id === value.id) === index
    )

    return (
        <div className={styles.root}>
            <GridRow width="content">
                <div className={styles.filter}>
                    <div
                        onClick={() => setFilter("all")}
                        className={cx(styles.filterItem, {
                            [styles.filterItemIsActive]: "all" === filter,
                        })}
                    >
                        {translate("products_grid.filter_all", language)}
                    </div>
                    {filterItems.map(item => (
                        <div
                            key={item.id}
                            onClick={() => setFilter(item.id)}
                            className={cx(styles.filterItem, {
                                [styles.filterItemIsActive]: item.id === filter,
                            })}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            </GridRow>
            <GridRow width="content">
                {items
                    .filter(
                        item =>
                            filter === "all" ||
                            item.node.data.categories.find(
                                category =>
                                    category.category.document &&
                                    category.category.document.id === filter
                            )
                    )
                    .map(item => (
                        <GridColumn
                            key={item.node.id}
                            gutterBottom
                            s={12}
                            m={6}
                            l={6}
                        >
                            <Box padding className={cx(styles.item)}>
                                <div>
                                    {item.node.data.image &&
                                        item.node.data.image.fixed &&
                                        item.node.data.image.fixed.src && (
                                            <img
                                                src={
                                                    item.node.data.image.fixed
                                                        .src
                                                }
                                                alt={item.node.data.name.text}
                                            />
                                        )}
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.publication_date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Headline
                                            variant="h5"
                                            gutterBottom={false}
                                        >
                                            {item.node.data.name.text}
                                        </Headline>
                                        <Text>
                                            {item.node.data.teaser.text}
                                        </Text>
                                        <a
                                            href={item.node.data.file.url}
                                            target="_blank"
                                            onClick={() => {
                                                trackFileDownload(
                                                    item.node.data.file.url,
                                                    item.node.data.file.size
                                                )
                                            }}
                                        >
                                            {translate(
                                                "newsroom.download_file",
                                                language
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </Box>
                        </GridColumn>
                    ))}
            </GridRow>
        </div>
    )
}
