import React, { useEffect, useState } from "react"
import { Text } from "~components/atoms/Text"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/history-events-list.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Logo } from "~components/atoms/Logo"
import { graphql, navigate, useStaticQuery } from "gatsby"
import cx from "classnames"
import { resolveLink, useIntersect } from "~utility/Sink"
import { ContentSection } from "~components/atoms/ContentSection"

const Item = ({ item }) => {
    const [ref, entry] = useIntersect({ rootMargin: "0px", threshold: 0.5 })
    const [inView, setInView] = useState(false)

    useEffect(() => {
        if (entry && entry.isIntersecting) {
            setInView(true)
        }
    }, [entry])

    return (
        <div
            ref={ref}
            data-id={item.id}
            className={cx(styles.item, {
                [styles.itemIsInView]: inView,
                [styles.itemIsLinked]: item.data.link.uid,
                [styles.itemIsExternal]: !item.data.internal_event,
            })}
            onClick={() =>
                item.data.link.uid
                    ? navigate(resolveLink(item.data.link))
                    : false
            }
        >
            <div className={styles.itemImage}>
                {item.data.image && item.data.image.url && (
                    <img src={item.data.image.url} />
                )}
            </div>
            <div className={styles.itemText}>
                <time
                    className={cx(styles.itemTime, {
                        [styles.itemTimeIsBig]: item.data.top_event,
                    })}
                >
                    <LocalizedDate
                        form="yyyy"
                        date={new Date(item.data.date)}
                    />
                </time>
                <Headline variant="h3">{item.data.name.text}</Headline>
                <Text style={!item.data.internal_event ? "small" : "normal"}>
                    {item.data.description.text}
                </Text>
            </div>
        </div>
    )
}

export function HistoryEventsSlice({ activeDocMeta, data }) {
    const events = useStaticQuery(graphql`
        query HistoryEventsSliceQuery {
            events: allPrismicHistoryEvent(
                sort: { fields: data___date, order: DESC }
            ) {
                edges {
                    node {
                        id
                        lang
                        data {
                            date
                            description {
                                text
                            }
                            link {
                                uid
                                type
                            }
                            image {
                                url
                                thumbnails {
                                    mobile {
                                        url
                                    }
                                }
                            }
                            internal_event
                            top_event
                            name {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <GridRow width="content">
            <GridColumn gutterBottom s={12} m={12} l={12}>
                <ContentSection className={styles.header}>
                    {data.primary.subline.text && (
                        <Headline variant="h5" gutterBottom={false}>
                            {data.primary.subline.text}
                        </Headline>
                    )}
                    {data.primary.headline.text && (
                        <Headline variant="h2">
                            {data.primary.headline.text}
                        </Headline>
                    )}
                    {data.primary.text.text && (
                        <Text>{data.primary.text.text}</Text>
                    )}
                </ContentSection>
                <div className={styles.root}>
                    <Logo className={styles.logo} />
                    {events.events.edges
                        .filter(item => item.node.lang === activeDocMeta.lang)
                        .map(item => (
                            <Item item={item.node} key={item.node.id} />
                        ))}
                </div>
            </GridColumn>
        </GridRow>
    )
}
