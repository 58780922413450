import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/newsroom.module.scss"
import { Box } from "~components/atoms/Box"
import { localizeRoute, resolveLink, translate } from "~utility/Sink"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Text } from "~components/atoms/Text"

import cx from "classnames"
import { trackFileDownload } from "../../utility/tracking"

export const NewsroomBoard = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query NewsroomQuery {
            exhibitions: allPrismicExhibition(
                sort: { fields: data___date_begin }
            ) {
                edges {
                    node {
                        id
                        lang
                        data {
                            date_begin
                            date_end
                            name {
                                text
                            }
                            text {
                                text
                            }
                            link {
                                url
                            }
                            booth_number
                            address {
                                text
                            }
                            logo {
                                url
                            }
                        }
                    }
                }
            }
            downloads: allPrismicDownload(
                filter: {
                    data: { top_download: { eq: true } }
                    tags: { in: "PR" }
                }
            ) {
                edges {
                    node {
                        data {
                            name {
                                text
                            }
                            image {
                                url
                            }
                            file {
                                url
                                size
                            }
                        }
                        lang
                    }
                }
            }
            news: allPrismicNews(sort: { fields: data___date, order: DESC }) {
                edges {
                    node {
                        id
                        data {
                            date
                            name {
                                text
                            }
                            type
                            image {
                                url
                            }
                        }
                        uid
                        type
                        lang
                    }
                }
            }
        }
    `)

    const adhocReleases = data.news.edges
        .filter(item => item.node.lang === language)
        .filter(item => item.node.data.type === "Ad-hoc Meldungen")
        .slice(0, 3)

    const downloads = data.downloads.edges
        .filter(item => item.node.lang === language)
        .slice(0, 3)

    const corporateNews = data.news.edges
        .filter(item => item.node.lang === language)
        .filter(item => item.node.data.type === "Corporate News")
        .slice(0, 3)

    const exhibitions = data.exhibitions.edges
        .filter(item => item.node.lang === language)
        .filter(
            item =>
                new Date(item.node.data.date_begin) >= new Date() ||
                (new Date(item.node.data.date_begin) <= new Date() &&
                    new Date(item.node.data.date_end) >= new Date())
        )
        .slice(0, 3)

    return (
        <div className={styles.root}>
            <GridRow width="content">
                <GridColumn gutterBottom s={12} m={6} l={6}>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("newsroom.corporate_news", language)}
                        buttonLabel={translate("newsroom.all_news", language)}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.corporate_news",
                                language
                            )}
                        </Headline>
                        <div className={styles.teaserList}>
                            {corporateNews.map((item, index) => (
                                <Link
                                    key={item.node.id}
                                    to={resolveLink(item.node)}
                                    className={cx(styles.teaserListLink, {
                                        [styles.teaserListLinkBig]: index === 0,
                                    })}
                                >
                                    {index === 0 &&
                                        item.node.data.image.url && (
                                            <img
                                                src={item.node.data.image.url}
                                                alt=""
                                            />
                                        )}
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </Box>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute(
                            "investor_relations.ad_hoc_releases",
                            language
                        )}
                        buttonLabel={translate(
                            "newsroom.all_ad_hoc_releases",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.ad_hoc_releases",
                                language
                            )}
                        </Headline>

                        <div className={styles.teaserList}>
                            {adhocReleases.map((item, index) => (
                                <Link
                                    key={item.node.id}
                                    to={resolveLink(item.node)}
                                    className={cx(styles.teaserListLink)}
                                >
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </Box>
                </GridColumn>
                <GridColumn gutterBottom s={12} m={6} l={6}>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("newsroom.downloads", language)}
                        buttonLabel={translate(
                            "newsroom.all_downloads",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate("newsroom.headline.downloads", language)}
                        </Headline>
                        {downloads.length > 0 && (
                            <div className={styles.downloadList}>
                                {downloads.map((item, index) => (
                                    <figure
                                        key={index}
                                        className={styles.downloadListItem}
                                    >
                                        <img
                                            src={item.node.data.image.url}
                                            alt={item.node.data.name.text}
                                        />
                                        <figcaption>
                                            <Text>
                                                {item.node.data.name.text}
                                            </Text>
                                            <a
                                                href={item.node.data.file.url}
                                                target="_blank"
                                                onClick={() => {
                                                    trackFileDownload(
                                                        item.node.data.file.url,
                                                        item.node.data.file.size
                                                    )
                                                }}
                                            >
                                                {translate(
                                                    "download",
                                                    language
                                                )}
                                            </a>
                                        </figcaption>
                                    </figure>
                                ))}
                            </div>
                        )}
                    </Box>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("newsroom.exhibitions", language)}
                        buttonLabel={translate(
                            "newsroom.all_exhibitions",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.exhibitions",
                                language
                            )}
                        </Headline>
                        <div className={styles.teaserList}>
                            {exhibitions.map((item, index) => (
                                <Link
                                    key={item.node.id}
                                    to={localizeRoute(
                                        "newsroom.exhibitions",
                                        language
                                    )}
                                    className={cx(
                                        styles.teaserListLink,
                                        styles.teaserListLinkBig
                                    )}
                                >
                                    {item.node.data.logo.url && (
                                        <img
                                            src={item.node.data.logo.url}
                                            alt=""
                                        />
                                    )}
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_begin
                                                    )
                                                }
                                            />{" "}
                                            –{" "}
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_end
                                                    )
                                                }
                                            />
                                        </time>
                                        <strong>
                                            {item.node.data.name.text}
                                        </strong>
                                        <Text>
                                            {item.node.data.address.text}
                                        </Text>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </Box>

                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("investor_relations.home", language)}
                        buttonLabel={translate("read_more", language)}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.investor_relations",
                                language
                            )}
                        </Headline>
                    </Box>
                </GridColumn>
            </GridRow>
        </div>
    )
}
