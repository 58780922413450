import React from "react"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { ContentSection } from "~components/atoms/ContentSection"
import { Accordion } from "~components/atoms/Accordion"
import { RteText } from "~components/atoms/RteText"

import * as styles from "~styles/elements/slices/accordion.module.scss"
import { Headline } from "~components/atoms/Headline"

export function AccordionSlice({ data }) {
    return (
        <div>
            <ContentSection>
                <GridRow width="content">
                    <GridColumn s={12} m={12} l={12}>
                        {data.primary.headline.text && (
                            <div className={styles.header}>
                                {data.primary.subline.text && (
                                    <Headline variant="h5" gutterBottom={false}>
                                        {data.primary.subline.text}
                                    </Headline>
                                )}
                                {data.primary.headline.text && (
                                    <Headline variant="h2">
                                        {data.primary.headline.text}
                                    </Headline>
                                )}
                                {data.primary.text.html && (
                                    <RteText content={data.primary.text.html} />
                                )}
                            </div>
                        )}

                        {data.items.map((item, i) => (
                            <Accordion
                                key={i}
                                borderBottom={i == data.items.length - 1}
                                headline={item.headline.text}
                                isOpened={item.is_opened}
                            >
                                <RteText content={item.text.html} />
                            </Accordion>
                        ))}
                    </GridColumn>
                </GridRow>
            </ContentSection>
        </div>
    )
}
