import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as newsroomStyles from "~styles/elements/list/newsroom.module.scss"
import * as styles from "~styles/elements/list/investor-relations.module.scss"
import { Box } from "~components/atoms/Box"
import { localizeRoute, resolveLink, translate } from "~utility/Sink"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { RteText } from "~components/atoms/RteText"
import { Text } from "~components/atoms/Text"

import cx from "classnames"
import { trackFileDownload } from "../../utility/tracking"

export const InvestorRelationsBoard = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query InvestorRelationsQuery {
            events: allPrismicFinancialCalendarEvent(
                sort: { fields: data___date_begin, order: ASC }
            ) {
                edges {
                    node {
                        data {
                            name {
                                text
                            }
                            date_end
                            date_begin
                            download {
                                document {
                                    ... on PrismicDownload {
                                        id
                                        data {
                                            file {
                                                url
                                                size
                                            }
                                        }
                                    }
                                }
                            }
                            annual_general_meeting {
                                id
                                document {
                                    ... on PrismicGeneralMeeting {
                                        lang
                                        data {
                                            name {
                                                text
                                            }
                                        }
                                        uid
                                        type
                                    }
                                }
                            }
                            date_as_text {
                                text
                            }
                        }
                        lang
                    }
                }
            }
            downloads: allPrismicDownload(
                filter: {
                    data: { top_download: { eq: true } }
                    tags: { in: "IR" }
                }
            ) {
                edges {
                    node {
                        data {
                            name {
                                text
                            }
                            image {
                                url
                            }
                            file {
                                url
                                size
                            }
                        }
                        lang
                    }
                }
            }
            meetings: allPrismicGeneralMeeting(
                sort: { fields: data___date, order: DESC }
            ) {
                edges {
                    node {
                        id
                        data {
                            teaser_text {
                                html
                            }
                            date
                        }
                        lang
                    }
                }
            }
            exhibitions: allPrismicExhibition(
                sort: { fields: data___date_begin }
                filter: { isFuture: { eq: true } }
            ) {
                edges {
                    node {
                        lang
                        id
                        data {
                            date_begin
                            date_end
                            name {
                                text
                            }
                            text {
                                text
                            }
                            link {
                                url
                            }
                            booth_number
                            address {
                                text
                            }
                            logo {
                                url
                            }
                        }
                    }
                }
            }
            settings: allPrismicSettings {
                edges {
                    node {
                        lang
                        id
                        data {
                            stock_image {
                                url
                            }
                            articles_of_incorporation {
                                url
                            }
                            footer_ad_hoc_release {
                                html
                            }
                            footer_corporate_news {
                                html
                            }
                            footer_press_release {
                                html
                            }
                            management_text {
                                html
                            }
                            meta_description
                            meta_title
                            social_media {
                                name
                                link {
                                    url
                                }
                                icon {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            contact: allPrismicContact(
                filter: { data: { role: { eq: "Investor Relations" } } }
            ) {
                edges {
                    node {
                        lang
                        id
                        data {
                            address {
                                html
                            }
                            email {
                                text
                            }
                            name {
                                text
                            }
                            role
                            telephone {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                }
            }
            news: allPrismicNews(sort: { fields: data___date, order: DESC }) {
                edges {
                    node {
                        id
                        data {
                            date
                            name {
                                text
                            }
                            type
                        }
                        uid
                        type
                        lang
                    }
                }
            }
        }
    `)

    const settings = data.settings.edges.find(
        item => item.node.lang === language
    ).node.data

    const contact = data.contact.edges.find(item => item.node.lang === language)
        .node.data

    const adhocReleases = data.news.edges
        .filter(item => item.node.lang === language)
        .filter(item => item.node.data.type === "Ad-hoc Meldungen")
        .slice(0, 3)

    const meetings = data.meetings.edges
        .filter(item => item.node.lang === language)
        .slice(0, 1)

    const downloads = data.downloads.edges
        .filter(item => item.node.lang === language)
        .slice(0, 3)

    const events = data.events.edges
        .filter(item => item.node.lang === language)
        .filter(
            item =>
                new Date(item.node.data.date_begin).getFullYear() ===
                new Date().getFullYear()
        )

    return (
        <div className={styles.root}>
            <GridRow width="content">
                <GridColumn gutterBottom s={12} m={6} l={6}>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute(
                            "investor_relations.ad_hoc_releases",
                            language
                        )}
                        buttonLabel={translate(
                            "newsroom.all_ad_hoc_releases",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.ad_hoc_releases",
                                language
                            )}
                        </Headline>

                        <div className={newsroomStyles.teaserList}>
                            {adhocReleases.map((item, index) => (
                                <Link
                                    key={item.node.id}
                                    to={resolveLink(item.node)}
                                    className={cx(
                                        newsroomStyles.teaserListLink
                                    )}
                                >
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date
                                                    )
                                                }
                                            />
                                        </time>
                                        <Text>{item.node.data.name.text}</Text>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </Box>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("newsroom.corporate_news", language)}
                        buttonLabel={translate("newsroom.all_news", language)}
                    >
                        <Headline variant="h2">
                            {translate(
                                "newsroom.headline.corporate_news",
                                language
                            )}
                        </Headline>
                    </Box>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute("investor_relations.stock", language)}
                        buttonLabel={translate("read_more", language)}
                    >
                        <Headline variant="h2">
                            {translate(
                                "investor_relations.headline.stock",
                                language
                            )}
                        </Headline>
                        <Link
                            to={localizeRoute(
                                "investor_relations.stock",
                                language
                            )}
                        >
                            <img src={settings.stock_image.url} alt="" />
                        </Link>
                    </Box>
                    <Box padding>
                        <Headline variant="h2">
                            {translate(
                                "investor_relations.headline.contact",
                                language
                            )}
                        </Headline>
                        <Text>
                            <strong>{contact.name.text}</strong>
                            <br />
                            {contact.title.text}
                        </Text>

                        {contact.address && (
                            <RteText content={contact.address.html} />
                        )}
                        <Text>
                            {contact.telephone.text && (
                                <span>
                                    Tel. {contact.telephone.text}
                                    <br />
                                </span>
                            )}
                            <a href={"mailto:" + contact.email.text}>
                                {translate("contact_box.write_email", language)}
                            </a>
                        </Text>
                    </Box>
                </GridColumn>
                <GridColumn gutterBottom s={12} m={6} l={6}>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute(
                            "investor_relations.downloads",
                            language
                        )}
                        buttonLabel={translate(
                            "newsroom.all_downloads",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate("newsroom.headline.downloads", language)}
                        </Headline>
                        <div className={newsroomStyles.downloadList}>
                            {downloads.map((item, index) => (
                                <figure
                                    key={index}
                                    className={newsroomStyles.downloadListItem}
                                >
                                    <img
                                        src={item.node.data.image.url}
                                        alt={item.node.data.name.text}
                                    />
                                    <figcaption>
                                        <Text>{item.node.data.name.text}</Text>
                                        <a
                                            href={item.node.data.file.url}
                                            target="_blank"
                                            onClick={() => {
                                                trackFileDownload(
                                                    item.node.data.file.url,
                                                    item.node.data.file.size
                                                )
                                            }}
                                        >
                                            {translate("download", language)}
                                        </a>
                                    </figcaption>
                                </figure>
                            ))}
                        </div>
                    </Box>
                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute(
                            "investor_relations.financial_calendar",
                            language
                        )}
                        buttonLabel={translate(
                            "investor_relations.all_events",
                            language
                        )}
                        className={styles.financialCalendar}
                    >
                        <Headline variant="h2">
                            {translate(
                                "investor_relations.headline.financial_calendar",
                                language
                            )}{" "}
                            {new Date().getFullYear()}
                        </Headline>
                        <div className={styles.financialCalendarItems}>
                            {events.map((item, index) => (
                                <div
                                    key={index}
                                    className={styles.financialCalendarItem}
                                >
                                    <time>
                                        {item.node.data.date_as_text.text &&
                                            item.node.data.date_as_text.text}
                                        {!item.node.data.date_as_text.text &&
                                            item.node.data.date_begin ===
                                                item.node.data.date_end && (
                                                <LocalizedDate
                                                    date={
                                                        new Date(
                                                            item.node.data.date_begin
                                                        )
                                                    }
                                                />
                                            )}
                                        {!item.node.data.date_as_text.text &&
                                            item.node.data.date_begin !==
                                                item.node.data.date_end && (
                                                <>
                                                    <LocalizedDate
                                                        date={
                                                            new Date(
                                                                item.node.data.date_begin
                                                            )
                                                        }
                                                    />{" "}
                                                    –{" "}
                                                    <LocalizedDate
                                                        date={
                                                            new Date(
                                                                item.node.data.date_end
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                    </time>
                                    <Text>{item.node.data.name.text}</Text>
                                    <div
                                        className={
                                            styles.financialCalendarItemLinks
                                        }
                                    >
                                        {item.node.data.annual_general_meeting
                                            .document && (
                                            <Link
                                                to={`${localizeRoute(
                                                    "investor_relations.annual_general_meeting",
                                                    language
                                                )}/${
                                                    item.node.data
                                                        .annual_general_meeting
                                                        .document.uid
                                                }`}
                                            >
                                                {translate(
                                                    "read_more",
                                                    language
                                                )}
                                            </Link>
                                        )}
                                        {item.node.data.download.document?.data
                                            ?.file?.url && (
                                            <a
                                                href={
                                                    item.node.data.download
                                                        .document.data &&
                                                    item.node.data.download
                                                        .document.data.file &&
                                                    item.node.data.download
                                                        .document.data.file.url
                                                }
                                                onClick={() => {
                                                    trackFileDownload(
                                                        item.node.data.download
                                                            .document.data.file
                                                            .url,
                                                        item.node.data.download
                                                            .document.data.file
                                                            .size
                                                    )
                                                }}
                                                target="_blank"
                                            >
                                                {translate(
                                                    "download",
                                                    language
                                                )}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Box>

                    <Box
                        padding
                        gutterBottom
                        to={localizeRoute(
                            "investor_relations.annual_general_meeting",
                            language
                        )}
                        buttonLabel={translate(
                            "investor_relations.all_annual_general_meetings",
                            language
                        )}
                    >
                        <Headline variant="h2">
                            {translate(
                                "investor_relations.headline.annual_general_meeting",
                                language
                            )}
                        </Headline>
                        {meetings.map((item, index) => (
                            <div key={item.node.data.date}>
                                <RteText
                                    content={item.node.data.teaser_text.html}
                                />
                            </div>
                        ))}
                    </Box>
                </GridColumn>
            </GridRow>
        </div>
    )
}
