import React from "react"
import { Box } from "~components/atoms/Box"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import { Swiper, SwiperSlide } from "swiper/react"
import * as swiperStyles from "~styles/atoms/swiper.module.scss"
import SwiperCore, { Pagination, Autoplay } from "swiper"
import * as styles from "~styles/elements/slices/image-teaser-slider.module.scss"
import { RteText } from "~components/atoms/RteText"
import cx from "classnames"

SwiperCore.use([Pagination, Autoplay])

export function ImageTeaserSliderSlice({ data }) {
    const item = data.primary

    return (
        <div className={styles.root}>
            <ContentSection>
                <GridRow width="content">
                    <GridColumn s={12} m={12} l={12}>
                        <Headline variant="h2" gutterBottom="large">
                            {item.headline.text}
                        </Headline>

                        <Swiper
                            slidesPerView={1}
                            autoplay={{
                                delay: 6000,
                            }}
                            speed={1000}
                            pagination={{
                                type: "bullets",
                                clickable: true,
                                bulletClass: swiperStyles.bullet,
                                bulletActiveClass: swiperStyles.bulletIsActive,
                            }}
                        >
                            {data.items.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <div
                                        className={cx(
                                            styles.item,
                                            styles[`theme${item.layout}`]
                                        )}
                                    >
                                        <img
                                            className={styles.itemImage}
                                            src={item.image.url}
                                        />
                                        <Box className={styles.itemText}>
                                            <Headline
                                                gutterBottom={false}
                                                variant="h3"
                                            >
                                                {item.headline.text}
                                            </Headline>
                                            <RteText content={item.text.html} />
                                        </Box>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </GridColumn>
                </GridRow>
            </ContentSection>
        </div>
    )
}
