import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { NewsList } from "~components/elements/NewsList"

export const AdHocReleasesList = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query {
            news: allPrismicNews(
                sort: { fields: data___date, order: DESC }
                filter: { data: { type: { eq: "Ad-hoc Meldungen" } } }
            ) {
                edges {
                    node {
                        id
                        data {
                            type
                            name {
                                text
                            }
                            description {
                                text
                            }
                            date
                            image {
                                url
                            }
                        }
                        type
                        uid
                        lang
                    }
                }
            }
        }
    `)

    const items = data.news.edges.filter(item => item.node.lang === language)

    return <NewsList items={items} language={language} />
}
