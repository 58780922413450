import React from "react"
import * as styles from "~styles/atoms/button.module.scss"
import cx from "classnames"
import { navigate } from "gatsby-link"
import { resolveLink } from "~utility/Sink"

export const Button = ({
    children,
    withArrow = false,
    theme = "light",
    link = false,
    className,
    onClick = false,
    ...other
}) => {
    if (
        link &&
        link.url &&
        !["solution", "product", "page", "news", "home"].includes(link.type)
    ) {
        return (
            <a
                {...other}
                className={cx(
                    styles.root,
                    className,
                    { [styles.withArrow]: withArrow },
                    { [styles.themeDark]: theme == "dark" }
                )}
                href={link.url}
                target="_blank"
            >
                {children}
            </a>
        )
    }

    return (
        <button
            {...other}
            onClick={() => (onClick ? onClick() : navigate(resolveLink(link)))}
            className={cx(
                styles.root,
                className,
                { [styles.withArrow]: withArrow },
                { [styles.themeDark]: theme == "dark" }
            )}
        >
            {children}
        </button>
    )
}
