import React, { useEffect, useRef, useState } from "react"

import * as styles from "~styles/elements/slices/interactive-video.module.scss"
import * as markerStyles from "~styles/elements/slices/interactive-video/marker.module.scss"
import * as titleStyles from "~styles/elements/slices/interactive-video/title.module.scss"
import ArrowRedIcon from "~images/icon/icon-arrow-red.svg"
import cx from "classnames"
import { Headline } from "~components/atoms/Headline"
import { navigate } from "gatsby-link"
import { isBrowser, isDesktop, resolveLink, scrollToY } from "~utility/Sink"

function Marker({ item, time }) {
    const isVisible = item.fade_in_at <= time && item.fade_out_at > time

    const [isDesktop, setIsDesktop] = useState(true)

    useEffect(() => {
        if (!isBrowser()) return false

        if (window.innerWidth <= 700) {
            setIsDesktop(false)
        }
    })

    return (
        <div
            className={cx(
                markerStyles.root,
                {
                    [markerStyles.isVisible]: isVisible,
                },
                markerStyles[`theme${item.layout}`]
            )}
            style={{
                left:
                    (isDesktop
                        ? item.desktop_x_position
                        : item.mobile_x_position) + "%",
                top:
                    (isDesktop
                        ? item.desktop_y_position
                        : item.mobile_y_position) + "%",
            }}
            onClick={() => navigate(resolveLink(item.link))}
        >
            <div className={markerStyles.icon}>
                <img src={item.icon.url} alt="" />
            </div>
            <div className={markerStyles.label}>
                <strong>{item.headline.text}</strong>
                <span>{item.subline.text}</span>
            </div>
        </div>
    )
}

function Title({ item, time }) {
    const isVisible = item.fade_in_at <= time && item.fade_out_at > time

    return (
        <div
            className={cx(titleStyles.root, {
                [titleStyles.isVisible]: isVisible,
            })}
        >
            <Headline gutterBottom={false} variant="h1">
                {item.headline.text}
            </Headline>
            <span>{item.subline.text}</span>
        </div>
    )
}

export function InteractiveVideoSlice({ data }) {
    const [currentTime, setCurrentTime] = useState(0)
    const [isDesktop, setIsDesktop] = useState(true)

    const video = data.primary.video.document.data
    const titles = video.titles
    const markers = video.markers

    const headerRef = useRef(null)
    const videoRef = useRef(null)

    useEffect(() => {
        if (!isBrowser()) return false

        if (window.innerWidth <= 700) {
            setIsDesktop(false)
        }
    })

    useEffect(() => {
        let timer = window.setInterval(() => {
            setCurrentTime(Math.floor(videoRef.current.currentTime))
        }, 1000)

        return () => {
            window.clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        const headerRect = headerRef.current.getBoundingClientRect()
        const headerX = headerRect.left
        const headerY = headerRect.top

        const markers = Array.from(
            headerRef.current.querySelectorAll("." + markerStyles.root)
        )

        headerRef.current.addEventListener("mousemove", e => {
            const mouseX = Math.abs(headerX - e.clientX)
            const mouseY = Math.abs(headerY - e.clientY)
            let pointerIsCloseToItem = false

            markers.map(marker => {
                if (!marker.classList.contains(markerStyles.isVisible)) {
                    return false
                }

                const markerRect = marker.getBoundingClientRect()
                const markerX = marker.offsetLeft + markerRect.width / 2
                const markerY = marker.offsetTop + 25

                const xDistance = mouseX - markerX
                const yDistance = mouseY - markerY
                const distance = Math.sqrt(
                    Math.pow(Math.abs(xDistance), 2) +
                        Math.pow(Math.abs(yDistance), 2)
                )

                if (distance < 200) {
                    window.requestAnimationFrame(() => {
                        marker.classList.add(markerStyles.isNear)
                        marker.style.transform = `translateX(${
                            xDistance * 0.3
                        }px) translateY(${yDistance * 0.3}px)`
                    })
                    pointerIsCloseToItem = true
                } else {
                    window.requestAnimationFrame(() => {
                        marker.classList.remove(markerStyles.isNear)
                        marker.style.transform = `translateX(0) translateY(0)`
                    })
                }
            })

            if (pointerIsCloseToItem) {
                videoRef.current.playbackRate = 0
            } else {
                videoRef.current.playbackRate = 1
            }
        })
    }, [])

    const scrollToBottom = () => {
        const rect = headerRef.current.getBoundingClientRect()
        scrollToY(rect.height)
    }

    return (
        <div className={styles.root} ref={headerRef}>
            <div className={styles.video}>
                {isBrowser() && isDesktop && (
                    <video muted autoPlay loop ref={videoRef}>
                        <source
                            src={video.video_desktop.url}
                            type="video/webm"
                        />
                        {video.video_desktop_mp4 && (
                            <source
                                src={video.video_desktop_mp4.url}
                                type="video/mp4"
                            />
                        )}
                    </video>
                )}

                {isBrowser() && !isDesktop && (
                    <video
                        muted="muted"
                        playsInline="playsinline"
                        autoPlay
                        loop
                        ref={videoRef}
                    >
                        <source
                            src={video.video_mobile.url}
                            type="video/webm"
                        />
                        {video.video_mobile_mp4 && (
                            <source
                                src={video.video_mobile_mp4.url}
                                type="video/mp4"
                            />
                        )}
                    </video>
                )}
            </div>

            <div className={styles.titles}>
                {titles.map(title => (
                    <Title
                        key={title.headline.text}
                        item={title}
                        time={currentTime}
                    />
                ))}
            </div>

            <div className={styles.markers}>
                {markers.map(marker => (
                    <Marker
                        key={marker.headline.text + marker.subline.text}
                        time={currentTime}
                        item={marker}
                    />
                ))}
            </div>

            <div className={styles.arrow} onClick={() => scrollToBottom()}>
                <img src={ArrowRedIcon} alt="" />
            </div>
        </div>
    )
}
