import React from "react"

import * as styles from "~styles/elements/slices/quote.module.scss"

export function QuoteSlice({ data }) {
    const item = data.primary
    return (
        <div className={styles.root}>
            {item.image && item.image.url && (
                <picture>
                    <source srcSet={item.image.url} media="(min-width:600px)" />
                    <img src={item.image.thumbnails.mobile.url} />
                </picture>
            )}

            <div className={styles.quote}>
                <blockquote>{item.text.text}</blockquote>
                {item.subline.text && <span>{item.subline.text}</span>}
            </div>
        </div>
    )
}
