import React from "react"
import * as styles from "~styles/elements/slices/video.module.scss"
import * as bgStyles from "~styles/elements/layouts/default-layout.module.scss"
import cx from "classnames"
import { ContentSection } from "~components/atoms/ContentSection"
import { ResponsiveIframe } from "~components/atoms/ResponsiveIframe"
import { Headline } from "~components/atoms/Headline"
import { Text } from "~components/atoms/Text"
import { RteText } from "~components/atoms/RteText"
import useConsent, { EMBEDDINGS } from "../../utility/hooks/UseConsent"
import { ConsentBlocker } from "../elements/ConsentBlocker/ConsentBlocker"

const PROVIDER_MAPPING = {
    YouTube: EMBEDDINGS.YOUTUBE,
    Vimeo: EMBEDDINGS.VIMEO,
}

export function VideoSlice({ data, activeDocMeta }) {
    const primary = data.primary

    const { isEmbeddingAccepted } = useConsent()
    const isConsentGiven = isEmbeddingAccepted(
        PROVIDER_MAPPING[primary.video.provider_name]
    )

    return (
        <ContentSection className={bgStyles.isDark}>
            <div className={cx(styles.root, styles[`theme${primary.layout}`])}>
                <div className={styles.text}>
                    {data.primary.subline && (
                        <Headline variant="h5">
                            {data.primary.subline.text}
                        </Headline>
                    )}
                    {data.primary.headline && (
                        <Headline variant="h2">
                            {data.primary.headline.text}
                        </Headline>
                    )}
                    {data.primary.text.html && (
                        <RteText content={data.primary.text.html} />
                    )}
                </div>
                <ResponsiveIframe
                    html={isConsentGiven ? primary.video.html : undefined}
                    placeholder={
                        !isConsentGiven ? (
                            <ConsentBlocker
                                lang={activeDocMeta.lang}
                                label={primary.video.provider_name}
                            />
                        ) : undefined
                    }
                />
            </div>
        </ContentSection>
    )
}
