import React, { useState } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { Text } from "~components/atoms/Text"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import * as styles from "~styles/elements/list/exhibition-list.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Box } from "~components/atoms/Box"
import cx from "classnames"

export const ExhibitionsList = ({ activeDocMeta }) => {
    const [filter, setFilter] = useState(new Date().getFullYear())
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query {
            exhibitions: allPrismicExhibition(
                sort: { fields: data___date_begin, order: ASC }
            ) {
                edges {
                    node {
                        id
                        lang
                        type
                        isFuture
                        data {
                            address {
                                text
                            }
                            booth_number
                            date_begin
                            date_end
                            link {
                                url
                            }
                            logo {
                                url
                            }
                            name {
                                text
                            }
                            text {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    const items = data.exhibitions.edges
        .filter(item => item.node.lang === language)
        .filter(
            item =>
                new Date(item.node.data.date_begin) >= new Date() ||
                (new Date(item.node.data.date_begin) <= new Date() &&
                    new Date(item.node.data.date_end) >= new Date())
        )

    let years = items
        .map(item => new Date(item.node.data.date_begin).getFullYear())
        .filter((value, index, self) => self.indexOf(value) === index)

    return (
        <div className={styles.list}>
            <GridRow width="content">
                <div className={styles.filter}>
                    {years.map(year => (
                        <div
                            key={year}
                            onClick={() => setFilter(year)}
                            className={cx(styles.filterItem, {
                                [styles.filterItemIsActive]: year === filter,
                            })}
                        >
                            {year}
                        </div>
                    ))}
                </div>
            </GridRow>
            <GridRow width="content">
                {items
                    .filter(
                        item =>
                            new Date(
                                item.node.data.date_begin
                            ).getFullYear() === filter
                    )
                    .map(item => (
                        <GridColumn
                            key={item.node.id}
                            gutterBottom
                            s={12}
                            m={12}
                            l={12}
                        >
                            <Box
                                padding
                                className={cx(styles.item)}
                                to={item.node.data.link.url}
                            >
                                <div className={styles.itemIsFlex}>
                                    <img src={item.node.data.logo.url} />
                                    <div>
                                        <time>
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_begin
                                                    )
                                                }
                                            />{" "}
                                            –{" "}
                                            <LocalizedDate
                                                date={
                                                    new Date(
                                                        item.node.data.date_end
                                                    )
                                                }
                                            />
                                        </time>
                                        <Headline variant="h4">
                                            {item.node.data.name.text}
                                        </Headline>
                                        <Text>{item.node.data.text.text}</Text>
                                        <address>
                                            {item.node.data.address.text}{" "}
                                            {item.node.data.booth_number && (
                                                <span>
                                                    (
                                                    {
                                                        item.node.data
                                                            .booth_number
                                                    }
                                                    )
                                                </span>
                                            )}
                                        </address>
                                    </div>
                                </div>
                            </Box>
                        </GridColumn>
                    ))}
            </GridRow>
        </div>
    )
}
