import React from "react"
import * as styles from "~styles/atoms/responsive-iframe.module.scss"
import cx from "classnames"

export function ResponsiveIframe({ html, className, placeholder, ...others }) {
    return (
        <div
            className={cx(styles.root, className)}
            dangerouslySetInnerHTML={html ? { __html: html } : undefined}
            {...others}
        >
            {placeholder}
        </div>
    )
}
