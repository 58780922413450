import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { resolveLink } from "~utility/Sink"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import cx from "classnames"
import * as styles from "~styles/elements/slices/teaser-grid.module.scss"
import { Text } from "~components/atoms/Text"
import { RteText } from "~components/atoms/RteText"

const Item = ({ item }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div
            className={styles.item}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                className={cx(styles.itemForeground, {
                    [styles.itemForegroundIsActive]: isHovered,
                })}
            >
                {item.icon && <img src={item.icon.url} alt="" />}
                <Headline gutterBottom={false} variant="h5">
                    {item.headline.text}
                </Headline>
                <RteText content={item.text.html} />
                {item.link.uid && (
                    <Link to={resolveLink(item.link)}>
                        {item.button_label.text}
                    </Link>
                )}
            </div>
            <div className={styles.itemBackground}>
                {item.image && <img src={item.image.url} alt="" />}
                <div className={styles.itemBackgroundContent}>
                    <Headline gutterBottom={false} variant="h5">
                        {item.headline.text}
                    </Headline>
                    {item.subline && <Text>{item.subline.text}</Text>}
                </div>
            </div>
        </div>
    )
}

export function TeaserGridSlice({ data }) {
    const primary = data.primary
    return (
        <div className={cx(styles.root, styles[`theme${primary.layout}`])}>
            <ContentSection>
                <GridRow width="content">
                    <GridColumn s={12}>
                        <Headline variant="h2">
                            {primary.headline.text}
                        </Headline>
                    </GridColumn>
                </GridRow>

                <div className={styles.items}>
                    {data.items.map((item, i) => (
                        <Item item={item} key={i} />
                    ))}
                </div>
            </ContentSection>
        </div>
    )
}
