import React from "react"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { resolveLink } from "~utility/Sink"
import { ContentSection } from "~components/atoms/ContentSection"
import { Box } from "~components/atoms/Box"
import * as styles from "~styles/elements/layouts/default-layout.module.scss"
import { Text } from "~components/atoms/Text"

export function TilesSlice({ data }) {
    return (
        <div>
            <ContentSection className={styles.isDark}>
                <GridRow width="content">
                    {data.items.map((item, i) => (
                        <GridColumn key={i} gutterBottom s={12} m={6} l={6}>
                            <Box
                                padding
                                dark={item.layout == "2"}
                                to={resolveLink(item.link)}
                                externalLink={
                                    item.link.url?.includes("https:") ||
                                    item.link.url?.includes("mailto:")
                                }
                                buttonLabel={item.link_label.text}
                            >
                                <Headline variant="h2">
                                    {item.headline.text}
                                </Headline>
                                <Text>{item.subline.text}</Text>
                            </Box>
                        </GridColumn>
                    ))}
                </GridRow>
            </ContentSection>
        </div>
    )
}
