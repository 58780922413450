import { useStaticQuery, graphql } from "gatsby"

export function useSettings({ language }) {
    const data = useStaticQuery(graphql`
        query UseSettingsHookQuery {
            settings: allPrismicSettings {
                edges {
                    node {
                        lang
                        id
                        data {
                            stock_image {
                                url
                            }
                            cookie_banner_optin_text {
                                html
                            }
                            cookie_banner_link_text {
                                html
                            }
                            link_speculative_application {
                                url
                            }
                            articles_of_incorporation {
                                url
                            }
                            footer_ad_hoc_release {
                                html
                            }
                            footer_corporate_news {
                                html
                            }
                            footer_press_release {
                                html
                            }
                            meta_description
                            meta_title
                            social_media {
                                name
                                link {
                                    url
                                }
                                icon {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return data.settings.edges.find(item => item.node.lang === language).node
        .data
}
