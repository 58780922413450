import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { localizeRoute, translate } from "~utility/Sink"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/annual-general-meeting-list.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Box } from "~components/atoms/Box"

export const AnnualGeneralMeetingList = ({ activeDocMeta }) => {
    const language = activeDocMeta.lang

    const data = useStaticQuery(graphql`
        query {
            meetings: allPrismicGeneralMeeting(
                sort: { fields: data___date, order: DESC }
            ) {
                edges {
                    node {
                        data {
                            name {
                                text
                            }
                            date
                        }
                        uid
                        lang
                    }
                }
            }
        }
    `)

    const items = data.meetings.edges.filter(
        item => item.node.lang === language
    )

    return (
        <div className={styles.list}>
            <GridRow width="content">
                {items.map(item => (
                    <GridColumn
                        key={item.node.id}
                        gutterBottom
                        s={12}
                        m={12}
                        l={12}
                    >
                        <Box
                            padding
                            className={styles.item}
                            buttonLabel={translate("read_more", language)}
                            to={`${localizeRoute(
                                "investor_relations.annual_general_meeting",
                                language
                            )}/${item.node.uid}`}
                        >
                            <div>
                                <time>
                                    <LocalizedDate
                                        date={new Date(item.node.data.date)}
                                    />
                                </time>
                                <Headline variant="h4">
                                    {item.node.data.name.text}
                                </Headline>
                            </div>
                        </Box>
                    </GridColumn>
                ))}
            </GridRow>
        </div>
    )
}
