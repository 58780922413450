import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ProductsGrid } from "~components/elements/ProductsGrid"

export function ProductsGridSlice({ activeDocMeta }) {
    const data = useStaticQuery(graphql`
        query ProductsGridSliceQuery {
            products: allPrismicProduct(
                sort: { order: ASC, fields: data___sort_order }
            ) {
                edges {
                    node {
                        uid
                        lang
                        type
                        data {
                            name {
                                text
                            }
                            teaser {
                                text
                            }
                            logo {
                                url
                            }
                            main_image {
                                url
                            }
                            categories {
                                category {
                                    slug
                                    document {
                                        ... on PrismicCategory {
                                            id
                                            data {
                                                name {
                                                    text
                                                }
                                                sort_order
                                            }
                                        }
                                    }
                                }
                            }
                            division {
                                document {
                                    ... on PrismicDivision {
                                        id
                                    }
                                }
                            }
                            shortcut
                        }
                    }
                }
            }
        }
    `)

    return (
        <ProductsGrid
            activeDocMeta={activeDocMeta}
            products={data.products.edges.filter(
                product => product.node.lang === activeDocMeta.lang
            )}
        />
    )
}
