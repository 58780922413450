import React from "react"
import { QuoteSlice } from "~components/slices/QuoteSlice"
import { Swiper, SwiperSlide } from "swiper/react"
import * as swiperStyles from "~styles/atoms/swiper.module.scss"

import SwiperCore, { Pagination, Autoplay } from "swiper"
SwiperCore.use([Pagination, Autoplay])

export function QuoteSliderSlice({ data }) {
    return (
        <div>
            <Swiper
                slidesPerView={1}
                autoplay={{
                    delay: 6000,
                }}
                speed={1000}
                pagination={{
                    type: "bullets",
                    clickable: true,
                    bulletClass: swiperStyles.bullet,
                    bulletActiveClass: swiperStyles.bulletIsActive,
                }}
            >
                {data.items.map((item, i) => (
                    <SwiperSlide key={i}>
                        <QuoteSlice data={{ primary: item }} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
