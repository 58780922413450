import React from "react"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import cx from "classnames"

import * as styles from "~styles/elements/slices/counting-numbers.module.scss"

export function CountingNumbersSlice({ data }) {
    const primary = data.primary
    const items = data.items

    return (
        <div className={cx(styles.root, styles[`theme${primary.layout}`])}>
            <ContentSection>
                <GridRow width="content">
                    <GridColumn s={12} m={12} l={12}>
                        {primary.headline.text && (
                            <Headline
                                gutterBottom={false}
                                variant="h2"
                                className={styles.headline}
                            >
                                {primary.headline.text}
                            </Headline>
                        )}
                        <div className={styles.items}>
                            {items.map((item, i) => (
                                <div key={i} className={styles.item}>
                                    {item.icon.url && (
                                        <div className={styles.icon}>
                                            <img src={item.icon.url} />
                                        </div>
                                    )}
                                    <div className={styles.number}>
                                        {item.prefix.text}
                                        <span>{item.number}</span>
                                        {item.suffix.text}
                                    </div>
                                    <span>{item.text.text}</span>
                                </div>
                            ))}
                        </div>
                    </GridColumn>
                </GridRow>
            </ContentSection>
        </div>
    )
}
