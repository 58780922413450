import React, { useState } from "react"
import { navigate } from "gatsby"
import { resolveLink } from "~utility/Sink"
import { Text } from "~components/atoms/Text"
import { Headline } from "~components/atoms/Headline"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/list/news-list.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { Box } from "~components/atoms/Box"

import cx from "classnames"

export const NewsList = ({ items, language }) => {
    const [filter, setFilter] = useState(new Date().getFullYear())

    let years = items
        .map(item => new Date(item.node.data.date).getFullYear())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => b - a)

    return (
        <div className={styles.list}>
            <GridRow width="content">
                <div className={styles.filter}>
                    {years.map(year => (
                        <div
                            key={year}
                            onClick={() => setFilter(year)}
                            className={cx(styles.filterItem, {
                                [styles.filterItemIsActive]: year === filter,
                            })}
                        >
                            {year}
                        </div>
                    ))}
                </div>
            </GridRow>
            <GridRow width="content">
                {items
                    .filter(
                        item =>
                            new Date(item.node.data.date).getFullYear() ===
                            filter
                    )
                    .map(item => (
                        <GridColumn
                            key={item.node.id}
                            gutterBottom
                            s={12}
                            m={6}
                            l={6}
                        >
                            <Box
                                arrow
                                padding
                                className={styles.item}
                                onClick={() => navigate(resolveLink(item.node))}
                            >
                                <div>
                                    <time>
                                        <LocalizedDate
                                            date={new Date(item.node.data.date)}
                                        />
                                    </time>
                                    <Headline variant="h4">
                                        {item.node.data.name.text}
                                    </Headline>
                                    <Text>
                                        {item.node.data.description.text}
                                    </Text>
                                </div>
                            </Box>
                        </GridColumn>
                    ))}
            </GridRow>
        </div>
    )
}
