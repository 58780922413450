import { Link, navigate } from "gatsby"
import React from "react"
import { resolveLink, translate } from "~utility/Sink"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import cx from "classnames"

import * as styles from "~styles/elements/slices/image-teaser-group.module.scss"
import { RteText } from "~components/atoms/RteText"

export function ImageTeaserGroupSlice({ activeDocMeta, data }) {
    const primary = data.primary

    return (
        <div className={cx(styles.root, styles[`theme${primary.layout}`])}>
            <ContentSection>
                {primary.headline.text && (
                    <GridRow width="content">
                        <GridColumn s={12}>
                            <header className={styles.header}>
                                <Headline variant="h2">
                                    {primary.headline.text}
                                </Headline>
                                <RteText content={primary.text.html} />
                            </header>
                        </GridColumn>
                    </GridRow>
                )}

                <GridRow width="content">
                    {data.items.map((item, i) => (
                        <GridColumn key={i} s={12} m={12 / data.items.length}>
                            <div className={styles.item}>
                                {item.image && item.image.url && (
                                    <div className={styles.image}>
                                        {item.link && item.link.uid && (
                                            <Link to={resolveLink(item.link)}>
                                                <img
                                                    src={item.image.url}
                                                    alt={item.subline.text}
                                                />
                                            </Link>
                                        )}
                                        {(!item.link || !item.link.uid) && (
                                            <img
                                                src={item.image.url}
                                                alt={item.subline.text}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className={styles.content}>
                                    <Headline gutterBottom={false} variant="h4">
                                        {item.subline.text}
                                    </Headline>
                                    <RteText content={item.text.html} />
                                    {item.link &&
                                        item.link.uid &&
                                        primary.layout !== "2" && (
                                            <Link to={resolveLink(item.link)}>
                                                {item.link_label?.text
                                                    ? item.link_label.text
                                                    : translate(
                                                          "read_more",
                                                          activeDocMeta.lang
                                                      )}
                                            </Link>
                                        )}
                                </div>
                            </div>
                        </GridColumn>
                    ))}
                    <GridColumn s={12}></GridColumn>
                </GridRow>
            </ContentSection>
        </div>
    )
}
