import React from "react"
import cx from "classnames"

import * as styles from "./FilterLayout.module.scss"

const FilterLayout = ({ children }) => {
    return <div className={cx(styles.FilterLayout, {})}>{children}</div>
}

export default FilterLayout
